.column  {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Raleway', sans-serif;
}

.headerHeight {
  min-height: 110px;
}

.headerRow {
  composes: row headerHeight;
  position: fixed;
  width: calc(100% - 40px);
  z-index: 100;
  background-color: white;
  min-height: 70px;
  /*background-color: #a8cfd8;*/
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.headerRowBlock {
  composes: headerHeight;
}

.navHome {
  composes: headerHeight;
  max-height: 100px;
  cursor: pointer;
}

.navLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(52, 105, 133);
}
.navLink {
  padding: 0 20px;
  cursor: pointer;
  font-size: 24px;
}

.coverPhotoRow {
  display: flex;
  flex-direction: column;
  /*background-color: #a8cfd8;*/
  color: rgb(52, 105, 133);
  width: 100%;
}

.coverPhotoImgShell,
.coverPhotoImg {
  max-width: 14%;
  height: auto;
}

.coverPhotoContainer {
  composes: column;
  width: 100%;
  justify-content: center;
}

.coverPhoto {
  width: 100%;
}

.coverImages {
  display: flex;
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap-reverse;
  justify-content: center;
}

.coverPhotoText {
  display: flex;
  justify-content: center;
  padding: 40px 0;
  font-size: 36px;
  text-align: center;
}

.coverPhotoTextOld {
  composes: coverPhotoText;
  text-decoration: line-through;
  padding-bottom: 0;
}

.coverPhotoSubText {
  composes: coverPhotoText;
  font-family: 'Dancing Script', cursive;
}

.textContainer {
  margin-top: 50px;
}

.nameText {
  composes: coverPhotoText;
  font-family: 'Dancing Script', cursive;
  text-align: center;
  font-size: 90px;
  font-weight: 300;
  padding: 0;
  color: #e0c383;
}

.nameToText {
  composes: nameText;
  font-family: 'Dancing Script', cursive;
}

.eventsSection {
  width: 100%;
  display: flex;
  padding: 75px 0;
  /*background-color: #ccf3ff;*/
  color: rgb(52, 105, 133);
}

.eventsImage {
  display: flex;
  width: 50%;
}

.eventsInfo {
  flex-direction: column;
  display: flex;
  width: 100%;
}

.sectionTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 64px;
  font-weight: 300;
  color: rgb(52, 105, 133);
  text-transform: uppercase;
}

.eventsTitle {
  composes: sectionTitle;
  font-size: 36px;
  text-align: center;
}

.eventsList {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
}

.event {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 30px 0;
}

.eventTitle {
  font-size: 48px;
  padding-bottom: 20px;
  font-family: 'Dancing Script', cursive;
  font-weight: bold;
}
.eventDate {
  font-size: 30px;
}
.eventTime {
  font-size: 24px;
  font-style: italic;
}
.eventAttire {
  composes: eventTime;
}
.eventAddress {
  font-size: 24px;
  padding: 20px 0;
}
.eventHostContainer {
  margin-bottom: 20px;
}
.eventHost {
  font-size: 30px;
  font-style: italic;
}
.eventTransport {
  composes: eventTime;
  margin-top: 20px;
}

.whereSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 75px 0;
  border-top: 1px solid rgb(52, 105, 133);
}

.whereTitle {
  composes: sectionTitle;
}

.whereTextContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  font-style: italic;
}

.whereText {
  font-size: 24px;
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 60px 45px;
  color: rgb(52, 105, 133);
}

.whereAddress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: rgb(52, 105, 133);
  text-align: center;
}

.travelSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 75px 0;
  color: rgb(52, 105, 133);
  border-top: 1px solid rgb(52, 105, 133);
}

.travelTitle {
  composes: sectionTitle;
}

.travelTextContainer {
  max-width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.travelText {
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 60px 45px;
  max-width: 800px;
}

.travelOptionTitle {
  font-weight: bold;
}

.travelOption {
  font-size: 24px;
  margin: 30px 45px;
  width: 600px;
  max-width: calc(100% - 120px);
}

.registries {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 80%;
  align-self: center;
  width: 1000px;
}

.registrySection {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 75px 0;
  border-top: 1px solid rgb(52, 105, 133);
  color: rgb(52, 105, 133);
}

.registryTextContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.registryText {
  font-size: 24px;
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 60px 45px;
}

.registryTitle {
  composes: sectionTitle;
}

.heart {
  color: rgb(52, 105, 133);
}

.registryName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin: 20px 0;
  text-decoration: none;
}

.registryNameLink {
  composes: registryName;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
}

.registryImage {
  border-bottom: 1px solid black;
}

.registryImageSwans {
  composes: registryImage;
  /*background-color: white;*/
  width: 135px;
  height: 135px;
  padding: 10px;
}

.registryCopy  {
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px 0;
}

.photoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 75px 0;
  border-top: 1px solid rgb(52, 105, 133);
}

.photoTitle {
  composes: sectionTitle;
  margin-bottom: 50px;
  text-align: center;
}

.sweaters {
  composes: eventAttire;
  margin: 0 30px;
}

.weatherWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 75px;
}

.albumWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.albumName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin: 20px 5px;
  text-decoration: none;
}

.albumImage {
  width: 135px;
  height: 135px;
  padding: 10px;
}

.albumNameLink {
  composes: albumName;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.albumCopy {
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px 0;
  color: rgb(52, 105, 133);
}

@media only screen and (max-width: 600px) {
  .navHome {
    max-height: 70px;
  }
  .navHomeContainer {
    display: none;
  }
  .textContainer {
    margin-top: 10px
  }

  .eventsImage {
    display: none;
  }

  .eventsInfo {
    width: 100%;
  }

  .coverPhotoText {
    font-size: 24px;
  }

  .nameText {
    font-size: 64px;
  }

  .coverPhotoImg {
    width: 50%;
    max-width: 50%;
  }
  .coverPhotoImgShell {
    display: none;
  }
  .navLink {
    padding: 0 10px;
    cursor: pointer;
    font-size: 16px;
  }
  .registries {
    flex-direction: column;
  }

  .registryName {
    margin: 10px 0;
  }

}

